(<template>
  <div class="assignment-breadcrumbs">
    <div class="assignment-breadcrumbs__wrapper">
      <router-link :to="assignmentsLink"
                   class="assignment-breadcrumbs__link">{{ $gettext('My assignments') }}</router-link>
      <span class="assignment-breadcrumbs__arrow"></span>
      <button class="assignment-breadcrumbs__link">{{ $pgettext('custom', 'Assignment') }}</button>
    </div>
    <div class="assignment-breadcrumbs__btn-cont">
      <button v-if="showPrintBtn"
              class="assignment-breadcrumbs__btn assignment-breadcrumbs__btn--print"
              @click="printAssignment">{{ $gettext('Print') }}</button>
      <button v-if="awardedStatus"
              class="assignment-breadcrumbs__btn assignment-breadcrumbs__btn--email"
              @click="openSendDetails">{{ $gettext('Email') }}</button>
      <button v-if="awardedStatus"
              class="assignment-breadcrumbs__btn assignment-breadcrumbs__btn--sms"
              @click="openSendSms">{{ $gettext('SMS') }}</button>
    </div>
  </div>
</template>)

<script>
  import {mapState, mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsNotInterpreter',
        'userIsEnterpriseMember',
        'userEmail',
        'userPhone'
      ]),
      ...mapState('OneAssignmentStore', {
        storeJobObj: ({storeJobObj}) => storeJobObj || {}
      }),
      awardedStatus() { return ['accepted', 'in_progress'].includes(this.storeJobObj.status); },
      interpretationRequirements() { return this.storeJobObj.interpretationRequirement || {}; },
      assignmentAttenders() { return this.interpretationRequirements.attenders || []; },
      assignmentBookerInfo() {
        return this.assignmentAttenders.find((item) => item.uid == this.interpretationRequirements.bookerUid) || {};
      },
      showPrintBtn() {
        return this.userIsNotInterpreter && this.awardedStatus;
      },
      serverAssignmentsLink() {
        return this.$makeRoute({
          name: 'ServerAllAssignments',
          query: {
            view: 'current',
            sortConfirmedBy: 1,
            sortAvailableBy: 1,
            pageConfirmedBy: 1,
            pageAvailableBy: 1
          }
        });
      },
      buyerAssignmentsLink() {
        return this.$makeRoute({
          name: 'BuyerAllAssignments',
          query: {
            view: 'current',
            sort: 1,
            page: 1
          }
        });
      },
      assignmentsLink() { return this.userIsNotInterpreter ? this.buyerAssignmentsLink : this.serverAssignmentsLink; },
      preselectedEmail() { return this.userIsEnterpriseMember ? this.assignmentBookerInfo.email : this.userEmail; },
      preselectedPhoneCode() { return this.userIsEnterpriseMember ? this.assignmentBookerInfo.phoneCode : this.userPhone.code; },
      preselectedPhoneNumber() { return this.userIsEnterpriseMember ? this.assignmentBookerInfo.phoneNumber : this.userPhone.number; }
    },
    methods: {
      printAssignment() { window.print(); },
      openSendDetails() {
        this.$store.commit('ModalStore/setModal', {
          component: 'send-details',
          width: 400,
          data: {
            email: this.preselectedEmail
          }
        });
      },
      openSendSms() {
        this.$store.commit('ModalStore/setModal', {
          component: 'send-sms',
          classes: ['send-sms-modal'],
          width: 400,
          data: {
            phoneCode: this.preselectedPhoneCode,
            phoneNumber: this.preselectedPhoneNumber
          }
        });
      }
    }
  };
</script>

<style scoped>
  .assignment-breadcrumbs {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 20px;
    font-size: 12px;
  }

  .assignment-breadcrumbs__wrapper {
    display: flex;
  }

  .assignment-breadcrumbs__arrow {
    display: inline-block;
    align-self: stretch;
    width: 10px;
    margin: 0 10px;
    background-image: url(~@assets/imgs/arrows/big_arrow_to_right_trans.svg);
    background-position: 50% 50%;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .assignment-breadcrumbs__btn-cont {
    display: flex;
  }

  .assignment-breadcrumbs__btn {
    margin-left: 15px;
    padding-right: 25px;
    background-position: 100% 50%;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .assignment-breadcrumbs__btn:first-child {
    margin: 0;
  }

  .assignment-breadcrumbs__btn--print {
    background-image: url(~@assets/imgs/undefined_imgs/printer-img.svg);
  }

  .assignment-breadcrumbs__btn--email {
    padding-right: 30px;
    background-image: url(~@assets/imgs/undefined_imgs/email_ico.svg);
  }

  .assignment-breadcrumbs__btn--sms {
    padding-right: 28px;
    background-image: url(~@assets/imgs/undefined_imgs/sms_icon.svg);
  }

  .assignment-breadcrumbs__link:focus {
    box-shadow: var(--default-focus-shadow);
    outline: none;
  }

  @media (max-width: 767px) {
    .assignment-breadcrumbs {
      display: none;
    }
  }
</style>
